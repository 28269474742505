import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.scss";
import Loading from "./Loading";

const Home = lazy(() => import("./Pages/Home/Home"));
const Resume = lazy(() => import("./Pages/Resume/Resume"));
const Project = lazy(() => import("./Pages/Project/Project"));
const Blog = lazy(() => import("./Pages/Blogs/Blog"));
const Resources = lazy(() => import("./Pages/Resources/Resources"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const NotFound = lazy(() => import("./Pages/Errors/404"));

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/resume", element: <Resume /> },
  { path: "/projects/all", element: <Project /> },
  { path: "/projects/mobile", element: <Project /> },
  { path: "/projects/web", element: <Project /> },
  { path: "/projects/uiux", element: <Project /> },
  { path: "/blogs", element: <Blog /> },
  { path: "/resources", element: <Resources /> },
  { path: "/contacts", element: <Contact /> },
  { path: "*", element: <NotFound /> },
]);

var browserHeight = window.innerHeight;
const rootElement = document.getElementById("root");
rootElement.style.height = `${browserHeight}px`;

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>
);
